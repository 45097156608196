import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { signout } from './Auth/lib/cognito'
import { isTrial, isTrialExpired } from '../utils/permissions'

const PrivateHeaderLinks = ({
    history,
    logout,
    user,
    getMenuProps = () => ({}),
    getItemProps = () => ({}),
}) => {
    const trial = !user.loading ? isTrial(user.user) : false
    const trialExpired = !user.loading ? isTrialExpired(user.user) : false
    let timeLeft
    if (trial) {
        const cancellationDate = user.user.cancellationDate
        timeLeft = `${moment.unix(cancellationDate).fromNow(true)} Remaining`
    } else if (trialExpired) {
        timeLeft = 'Trial Expired'
    }
    return (
        <ul {...getMenuProps()} className="header-right-menu">
            <li {...getItemProps('dashboard')}>
                <Link className="topMenuItem" to={'/projects'}>
                    Dashboard
                </Link>
            </li>
            <li {...getItemProps('my-account')}>
                <Link
                    className="topMenuItem"
                    to={{
                        pathname: '/profile',
                        state: { modal: true, closeable: true, from: history.location },
                    }}>
                    My Account
                </Link>
            </li>
            <li {...getItemProps('billing')}>
                <Link
                    className="topMenuItem"
                    to={{
                        pathname: '/billing',
                        state: { modal: true, closeable: true, from: history.location },
                    }}>
                    {trial || trialExpired ? (
                        <span className="text-capitalize text-warning">{timeLeft}</span>
                    ) : (
                        'Billing'
                    )}
                </Link>
            </li>
            <li {...getItemProps('faq')}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="topMenuItem"
                    href="https://theplot.io/faq/">
                    FAQ
                </a>
            </li>
            <li {...getItemProps('logout')}>
                <span
                    className="topMenuItem"
                    onClick={() => {
                        logout()
                        // Cognito
                        if (user.user) {
                            signout({ email: user.user.email })
                        }
                    }}>
                    Logout
                </span>
            </li>
        </ul>
    )
}

PrivateHeaderLinks.propTypes = {
    route: PropTypes.func,
    logout: PropTypes.func,
}

export default withRouter(PrivateHeaderLinks)
